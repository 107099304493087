import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios'
import VueAxios from 'vue-axios'


axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;


axios.interceptors.request.use(function (request) {
  // установка headers для каждого запроса
  request.headers.authorization = store.getters.Authorization;
  return request;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    console.log('401');
    
    // если получили 401 ошибку то перекидывает на авторизацию
    let path = location.pathname.substr(1);
    store.dispatch('fetchLogout');
    
    if (path !== 'login/') {
      router.push({ 
        path: "/login/",
        query: {'next_page': `/${path}`}

      });
    }
  }
  return Promise.reject(error);
});

loadFonts()

createApp(App)
  .use(VueAxios, axios)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
