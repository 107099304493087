import axios from 'axios'


export default {
  fetchPeriodList: () => axios.get(`/api/bill/period_list/`),
  getBills: (year: number, month: number) => axios.get(`/api/bill/bills/?year=${year}&month=${month}`),
  deleteBill: (id: number) => axios.delete(`/api/bill/bills/${id}/`),
  updateBill: (id: number, body: object) => axios.patch(`/api/bill/bills/${id}/`, body),
  createBill: (body: object) => axios.post(`/api/bill/period_create/`, body),
  fetchPayment: () => axios.get(`/api/bill/payment/`),
  createPayment: (body: object) => axios.post(`/api/bill/payment/`, body),
  fetchPaymentChart: () => axios.get(`/api/bill/payment_chart/`),
}