<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ props: activatorProps }">
      <tr v-bind="activatorProps">
        <td>
          <div class="icon_status" v-if="bill.status === 1">
            <!-- <svg height="100%"
            width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2495 2H8.49395C8.31447 2 8.22473 2 8.14551 2.02733C8.07544 2.05149 8.01163 2.09093 7.95868 2.14279C7.89881 2.20143 7.85868 2.2817 7.77841 2.44223L3.57841 10.8422C3.38673 11.2256 3.29089 11.4173 3.31391 11.5731C3.33401 11.7091 3.40927 11.8309 3.52197 11.9097C3.65104 12 3.86534 12 4.29395 12H10.4995L7.49953 22L19.6926 9.35531C20.104 8.9287 20.3097 8.7154 20.3217 8.53288C20.3321 8.37446 20.2667 8.22049 20.1454 8.11803C20.0057 8 19.7094 8 19.1167 8H11.9995L14.2495 2Z" stroke="#BBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->
          </div>
          <div class="icon_status" v-else-if="bill.status === 2">
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 14L8.23309 16.4248C8.66178 16.7463 9.26772 16.6728 9.60705 16.2581L18 6" stroke="green"
                stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>

          <div v-else>
            ?
          </div>

        </td>
        <td> {{ bill.payment.name }} </td>
        <td> {{ bill.amount === null ? '-' : bill.amount }} </td>
      </tr>
    </template>

    <v-card :title="`Квитанция: ${bill.payment.name}`">
      <form v-on:submit.prevent="dialog = false; $emit('accept', billLocal)">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <h3>{{ `Период: ${bill.month}.${bill.year}` }}</h3>
            </v-col>

            <v-col cols="12" md="8" sm="12">
              <v-text-field autofocus v-model="billLocal.amount" type="number" label="Сумма" required />
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <v-select :items="statusList" v-model="billLocal.status" label="Статус" item-title="label"
                item-value="value" required />
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-checkbox color="primary" v-model="billLocal.presave"
                label="Сохранить сумму на следующий месяц"></v-checkbox>
            </v-col>
          </v-row>

          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="buttons">
            <div>
              <v-btn text="Удалить" color="error" variant="plain" @click="$emit('delete', billLocal.id); dialog = false"></v-btn>
            </div>
            <div>
              <v-btn text="Отмена" variant="plain" @click="dialog = false"></v-btn>
              <v-btn color="primary" text="Сохранить" variant="tonal" type="submit"></v-btn>
            </div>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>



<script lang="ts">
import { PeriodPaymentType } from '@/typing/main'
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: "UpdateBill",
  props: {
    bill: {
      type: Object as PropType<PeriodPaymentType>,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      billLocal: {} as PeriodPaymentType,
      statusList: [
        {
          label: "Создан",
          value: 1
        },
        {
          label: "Оплачено",
          value: 2
        },


      ],
    };
  },
  created() {
    this.billLocal = {...this.bill};
  },
});
</script>


<style scoped>
tr:hover {
  background-color: black;
}

tr {
  cursor: pointer;
}

.icon_status {
  width: 24px;
  height: 24px;
  
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
